/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Box } from "@theme-ui/components"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import Img from "gatsby-image"

const RelatedEventCard = ({ itemId }) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiEvent(sort: { fields: Date }) {
        edges {
          node {
            strapiId
            slug
            Biog
            Date
            dateFormatted: Date(formatString: "MMMM DD, YYYY")
            Img {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            Img_alt
            Open
            Title
            Type
            Venue
            strapiId
            Description
            Time
            blurb
          }
        }
      }
    }
  `)
  let eventDetails = undefined
  eventDetails = data.allStrapiEvent.edges.filter(item => {
    return item.node.strapiId === parseInt(itemId)
  })
  eventDetails = eventDetails[0]

  return (
    <Link
      to={`/event/${eventDetails.node.slug}`}
      sx={{ textDecoration: "none" }}
    >
      <Box
        sx={{ position: "relative" }}
        className={`event-${eventDetails.node.Type}`}
      >
        <Img
          fluid={eventDetails.node.Img.localFile.childImageSharp.fluid}
          alt={eventDetails.node.Img_alt}
          sx={{
            //width: "230px",
            height: "400px",
            // position: "absolute",
            // // top: "-16.6%", //-50px
            // // left: "20%", //60px
            // // zIndex: 5,
          }}
        />
        <Box
          sx={{
            width: "50px",
            height: "50px",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 4,
            bottom: 0,
          }}
        >
          <FaArrowRight
            sx={{
              position: "relative",

              color: "accent",

              fontSize: 5,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          px: 1,
          "& h1": {
            fontSize: 3,
            mt: 2,
            mb: 0,
            fontWeight: "400",
          },
          "& h2": {
            fontSize: 2,
            mt: 1,
            mb: 1,
            color: "muted",
            fontWeight: "400",
          },
          "& ul": {
            pl: 0,
            mt: 0,
            listStyle: "none",
            color: "muted",
          },
          "& li::before": {
            content: '"• "',
            color: "accent",
          },
        }}
      >
        <h1>{eventDetails.node.Title}</h1>
        <h2>{eventDetails.node.dateFormatted}</h2>
        {eventDetails.node.Time && (
          <ul>
            <li>{eventDetails.node.Time}</li>
          </ul>
        )}
      </Box>
    </Link>
  )
}
export default RelatedEventCard
