/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import React from "react"
import { Link } from "gatsby"

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
    .replace(/[_]+/, "-") //Replace underscores with dash
}

const PageSubnav = ({ submenu }) => {
  return (
    <Box
      sx={{
        mb: 4,
        ul: {
          pl: 0,
          listStyle: "none",
          mt: [1, -5, -5],
          li: {
            mb: 2,
          },
          a: {
            textDecoration: "none",
            p: 2,
            pl: 0,
            pb: 1,

            fontSize: [3, 4, 5],
            color: "muted",
            fontWeight: "bold",
            fontFamily: "heading",
            ":hover": {
              borderBottom: "2px solid red",
            },
          },
        },
      }}
    >
      <nav>
        <ul>
          {submenu.map((navItem, index) => {
            let slug = "/#"

            if (navItem.strapi_component === "navigation.nav-custom") {
              //Check its not null
              if (navItem.link_url === null) {
                return (
                  <li key={index}>
                    <a href={slug}>{navItem.link_text}</a>
                  </li>
                )
              }
              //ext link or internal
              else if (navItem.link_url.charAt(0) === "/") {
                slug = navItem.link_url
                return (
                  <li key={index}>
                    <Link to={slug}>{navItem.link_text}</Link>
                  </li>
                )
              } else {
                return (
                  <li key={index}>
                    <a href={slug}>{navItem.link_text}</a>
                  </li>
                )
              }
            }

            if (navItem.strapi_component === "navigation.nav-section") {
              slug = `/${slugify(navItem.page.nav_parent)}/${navItem.page.slug}`
            }
            if (navItem.strapi_component === "navigation.nav-news-item") {
              slug = `/news/${navItem.news_item.slug}`
            }
            if (navItem.strapi_component === "navigation.nav-event") {
              slug = `/event/${navItem.event.slug}`
            }

            return (
              <li key={index}>
                <Link to={slug}>{navItem.link_text}</Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </Box>
  )
}

export default PageSubnav
