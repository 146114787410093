/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Box } from "@theme-ui/components"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import Img from "gatsby-image"

const RelatedNewsItemCard = ({ itemId }) => {
  const data = useStaticQuery(graphql`
    query RelatedNewsItemQuery {
      allStrapiNewsItem(sort: { fields: date, order: DESC }) {
        edges {
          node {
            strapiId
            id
            title
            date
            dateFormatted: date(formatString: "MMMM DD, YYYY")
            content
            blurb
            slug
            img {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            img_alt
            news_categories {
              tag
            }
          }
        }
      }
      defaultImage: file(relativePath: { eq: "default-news-thumb.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  let newsDetails = undefined
  newsDetails = data.allStrapiNewsItem.edges.filter(item => {
    return item.node.strapiId === parseInt(itemId)
  })
  newsDetails = newsDetails[0]
  //default thumb or main thumb
  const mainImg = newsDetails.node.img
    ? newsDetails.node.img.localFile.childImageSharp.fluid
    : data.defaultImage.childImageSharp.fluid
  const mainImgAlt = newsDetails.node.img_alt
    ? newsDetails.node.img_alt
    : "Default News Artwork"

  return (
    <Link
      to={`/news/${newsDetails.node.slug}`}
      sx={{
        textDecoration: "none",
        "& h1": {
          fontSize: 4,
          mb: 0,
        },
        "& h2": {
          fontSize: 2,
          color: "muted",
        },
      }}
    >
      <Box sx={{ bg: "transparent", p: 0, position: "relative" }}>
        <Img fluid={mainImg} alt={mainImgAlt} sx={{ height: "300px" }} />
        <Box
          sx={{
            width: "50px",
            height: "50px",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 4,
            bottom: 0,
          }}
        >
          <FaArrowRight
            sx={{
              position: "relative",

              color: "accent",

              fontSize: 5,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ p: 2, "& h1": { mt: 0 } }}>
        <h1>{newsDetails.node.title}</h1>
        <h2>{newsDetails.node.dateFormatted}</h2>
        {newsDetails.node.news_categories &&
          newsDetails.node.news_categories.map((cat, index) => {
            return <a href={`/news/category/${cat.tag}`}>{cat.tag}</a>
          })}
      </Box>
    </Link>
  )
}
export default RelatedNewsItemCard
