/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid, Box } from "@theme-ui/components"
import Img from "gatsby-image"
import React, { useState, useCallback } from "react"
import SanitizedHTMLBodyCopy from "../components/sanitizedHTMLBodyCopy"
import PeopleItem from "./PeopleItem"
import { Collapse } from "react-collapse"
import { FaArrowDown } from "react-icons/fa"

const PeopleGrid = ({ items, defaultImage }) => {
  return (
    <Grid
      gap={4}
      columns={[1, 2, 4]}
      sx={{
        mb: 4,
        mx: 4,
        mt: 3,
        "& a": {
          color: "accent",
        },
        "& h1": {
          height: ["auto", "72px"], //2 rows if name long
          display: "flex",
          alignItems: "center",
          fontSize: [4, 5, 5],
        },
        "& h2": {
          fontSize: [3, 3, 4],
        },
        "& .peopleHeader": {},
      }}
    >
      {items.map((item, index) => {
        // console.log("peopleItem", item.image)
        // console.log("defaultItem", defaultImage)
        let image
        if (item.image) {
          image = item.image.localFile.childImageSharp.fluid
        } else {
          //default
          image = defaultImage.childImageSharp.fluid
        }
        return <PeopleItem data={item} key={`people-${index}`} image={image} />
      })}
    </Grid>
  )
}
export default PeopleGrid
