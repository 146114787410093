import { Grid } from "@theme-ui/components"
import React from "react"
import RelatedEventCard from "../components/relatedEventCard"
import RelatedNewsItemCard from "../components/relatedNewsItemCard"

const PageRelatedGrid = ({ items }) => {
  return (
    <Grid gap={3} columns={[1, 2, 4]} sx={{ mb: 4, mx: 4, mt: 3 }}>
      {items.map((item, index) => {
        // console.log(item)
        if (item.strapi_component === "sitewide.related-event") {
          return <RelatedEventCard key={index} itemId={item.event.id} />
        }
        if (item.strapi_component === "sitewide.related-news") {
          return <RelatedNewsItemCard key={index} itemId={item.news_item.id} />
          //return <p>newsitem</p>
        }
      })}
    </Grid>
  )
}
export default PageRelatedGrid
