/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useCallback } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Box, Flex, Grid } from "@theme-ui/components"
import SanitizedHTMLBodyCopy from "../components/sanitizedHTMLBodyCopy"
import { Collapse } from "react-collapse"
import { FaArrowDown } from "react-icons/fa"
import ShareIcons from "../components/shareIcons"
import HeaderBlockColor from "../components/headerBlockColor"
import HeaderBgImg from "../components/headerBgImg"
import PageGallery from "../components/pageGallery"
import PageSubnav from "../components/pageSubnav"
import PageRelatedGrid from "../components/pageRelatedGrid"
import PeopleGrid from "../components/peopleGrid"
import ReactPlayer from "react-player/lazy"

export const query = graphql`
  query SingePageQuery($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      strapiId
      Title
      nav_parent
      slug
      lead_content
      content
      small_content
      img {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      img_alt
      page_gallery {
        img_src {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        img_alt
      }
      subnav {
        event {
          slug
        }
        strapi_component
        link_text
        link_url
        news_item {
          slug
        }
        page {
          slug
          nav_parent
        }
      }
      multimedia_url
      related_grid {
        strapi_component
        title
        news_item {
          id
          title
        }
        event {
          id
          Title
        }
      }
      People {
        biog
        email
        id
        jobTitle
        name
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    defaultImage: file(
      relativePath: { eq: "default-page-script-reading.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    defaultPeopleImage: file(relativePath: { eq: "default-people-thumb.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const OtherPage = ({ data, location }) => {
  //single page
  const pageData = data.strapiPage

  return (
    <Layout>
      <SEO title={pageData.Title} />
      {pageData.img ? (
        <HeaderBgImg img={pageData.img} img_alt={pageData.img_alt} />
      ) : (
        <HeaderBlockColor />
      )}
      {/* <HeaderBgImg img={pageData.img} img_alt={pageData.img_alt} /> */}
      <Box
        sx={{
          mx: 5,
          background:
            "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
          transform: "rotate(-3deg) skew(-3deg) !important",
          py: 3,
          px: 3,
          position: "relative",
          mt: "-100px",
          mb: 3,
          display: "inline-block",
          "& h1": {
            color: "white",
            transform: "rotate(3deg) skew(3deg) !important",
          },
        }}
      >
        <h1>{pageData.Title}</h1>
      </Box>
      <Flex sx={{ mx: 4, display: ["block", "flex", null] }}>
        <Box
          sx={{
            width: ["100%", "60%", null],
            fontSize: 4,
            color: "muted",
            pr: 4,
            "h1,h2,h3,h4,h5,h6": {
              fontSize: 5,
            },
            "& .bodyCopy a": {
              color: "accent",
            },
            "& .bodyCopy img": {
              width: "100%",
            },
            "& .lead": {
              fontSize: 5,
              color: "text",
            },
            "& .small": {
              fontSize: 3,
            },
          }}
        >
          {pageData.lead_content && (
            <SanitizedHTMLBodyCopy
              theClass="lead"
              content={pageData.lead_content}
            />
          )}
          <SanitizedHTMLBodyCopy content={pageData.content} />
          {pageData.small_content && (
            <SanitizedHTMLBodyCopy
              theClass="small"
              content={pageData.small_content}
            />
          )}
          {/* <div dangerouslySetInnerHTML={{__html: pageData.content}}/> */}
        </Box>
        <Box sx={{ width: ["100%", "40%", null] }}>
          {pageData.subnav.length > 0 && (
            <PageSubnav submenu={pageData.subnav} />
          )}
          {pageData.page_gallery.length > 0 && (
            <PageGallery gallery={pageData.page_gallery} />
          )}
          {pageData.multimedia_url && (
            <div
              sx={{
                my: 4,
                position: "relative",
                paddingTop: "56.25%",
                "& .react-player": {
                  position: "absolute",
                  top: 0,
                  left: 0,
                },
              }}
            >
              <ReactPlayer
                className="react-player"
                url={pageData.multimedia_url}
                width="100%"
                height="100%"
              />
            </div>
          )}
        </Box>
      </Flex>
      <Box>
        {pageData.related_grid.length > 0 && (
          <PageRelatedGrid items={pageData.related_grid} />
        )}
      </Box>
      <Box>
        {pageData.People.length > 0 && (
          <PeopleGrid
            items={pageData.People}
            defaultImage={data.defaultPeopleImage}
          />
        )}
      </Box>
    </Layout>
  )
}

export default OtherPage
