/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid, Box } from "@theme-ui/components"
import Img from "gatsby-image"
import React, { useState, useCallback } from "react"
import SanitizedHTMLBodyCopy from "../components/sanitizedHTMLBodyCopy"
import { Collapse } from "react-collapse"
import { FaArrowDown } from "react-icons/fa"

const PeopleItem = props => {
  const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false)
  const onClick = useCallback(
    () => setIsButtonCollapseOpen(!isButtonCollapseOpen),
    [isButtonCollapseOpen]
  )

  return (
    <div>
      <div className="peopleHeader">
        <h1>{props.data.name}</h1>
        <Img
          fluid={props.image}
          alt={`${props.data.name} Headshot`}
          sx={{
            height: "200px",
            width: "200px",
            borderRadius: "50%",
          }}
        />
        <h2>{props.data.jobTitle}</h2>
      </div>
      <div className="peopleBody">
        <a href={`mailto:${props.data.email}`}>{props.data.email}</a>
        {props.data.biog && (
          <Box>
            <a
              aria-controls="open biog"
              aria-expanded={isButtonCollapseOpen}
              onClick={onClick}
              type="button"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                "& h3 svg.icon": {
                  fontSize: 2,
                  transform: "rotate(-90deg)",
                  transition: "all 0.2s ease-in-out",
                },
                "& h3 svg.icon.true": {
                  transform: "rotate(0deg)",
                },
              }}
            >
              <h3>
                Biog <FaArrowDown className={`icon ${isButtonCollapseOpen}`} />
              </h3>
            </a>
            <Collapse isOpened={isButtonCollapseOpen}>
              <SanitizedHTMLBodyCopy content={props.data.biog} />
            </Collapse>
          </Box>
        )}
      </div>
    </div>
  )
}
export default PeopleItem
