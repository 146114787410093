import React from "react"
import ImageGallery from "react-image-gallery"
/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import "react-image-gallery/styles/css/image-gallery.css"
import useHover from "../hooks/useHover"

const PageGallery = ({ gallery }) => {
  const [hoverRef, isHovered] = useHover()
  let sliderImages = []
  gallery.map(slide => {
    return sliderImages.push({
      original: slide.img_src.localFile.childImageSharp.fluid.src,
      originalAlt: slide.img_alt,
      thumbnail: slide.img_src.localFile.childImageSharp.fluid.src,

      //height: "800px",
      //width: "100%",
    })
  })
  // console.log(gallery)
  return (
    <Box
      ref={hoverRef}
      className={isHovered ? "hoveredbaby" : "nothovered"}
      sx={{
        my: 4,
        "& .image-gallery": {
          width: "100%",
          height: "auto",
        },
        "& .image-gallery-slide img": {
          width: "100%",
          height: "auto",
          maxHeight: "300px !important",
          objectFit: "cover",
          overflow: "hidden",
          objectPosition: "center center",
        },
        "& .fullscreen .image-gallery-slide img": {
          maxHeight: "100vh !important",
          objectFit: "contain",
        },
        "&.nothovered div.image-gallery button.image-gallery-icon": {
          opacity: "0",
        },
      }}
    >
      <ImageGallery
        items={sliderImages}
        showThumbnails={false}
        showBullets={true}
        autoPlay={true}
        slideInterval={4000}
        showFullscreenButton={true}
        //height={350}
      />
    </Box>
  )
}
export default PageGallery
